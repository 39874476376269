export const links = {
	guides: {
		ru: 'https://scope.gg/guides/ru',
		en: 'https://scope.gg/guides/',
		pt: 'https://scope.gg/guides/',
		fr: 'https://scope.gg/guides/',
		es: 'https://scope.gg/guides/',
	},

	blog: {
		ru: 'https://blog.scope.gg/ru/',
		en: 'https://blog.scope.gg/',
		pt: 'https://blog.scope.gg/',
		fr: 'https://blog.scope.gg/',
		es: 'https://blog.scope.gg/',
	},

	faq: {
		ru: 'https://scope.gg/faq-ru',
		en: 'https://scope.gg/faq',
		pt: 'https://scope.gg/faq',
		fr: 'https://scope.gg/faq',
		es: 'https://scope.gg/faq',
	},

	about: {
		ru: '#',
		en: '#',
	},

	clips: {
		ru: '/ru/clips',
		en: '/clips',
		pt: '/clips',
		fr: '/clips',
		es: '/clips',
	},

	prematch: {
		ru: '/ru/prematch',
		en: '/prematch',
		pt: '/prematch',
		fr: '/prematch',
		es: '/prematch',
	},

	csLineups: {
		ru: '/grenade-predictor-ru',
		en: '/grenade-predictor',
		pt: '/grenade-predictor',
		fr: '/grenade-predictor',
		es: '/grenade-predictor',
	},

	cookies: {
		ru: 'https://app.scope.gg/ru/cookies',
		en: 'https://app.scope.gg/cookies',
		pt: 'https://app.scope.gg/cookies',
		fr: 'https://app.scope.gg/cookies',
		es: 'https://app.scope.gg/cookies',
	},

	tou: {
		ru: 'https://app.scope.gg/ru/tos',
		en: 'https://app.scope.gg/tos',
		pt: 'https://app.scope.gg/tos',
		fr: 'https://app.scope.gg/tos',
		es: 'https://app.scope.gg/tos',
	},

	privacy: {
		ru: 'https://app.scope.gg/ru/privacy',
		en: 'https://app.scope.gg/privacy',
		pt: 'https://app.scope.gg/privacy',
		fr: 'https://app.scope.gg/privacy',
		es: 'https://app.scope.gg/privacy',
	},

	grenades: {
		ru: 'https://app.scope.gg/ru/grenades',
		en: 'https://scope.gg/grenade-predictor',
		pt: 'https://scope.gg/grenade-predictor',
		fr: 'https://scope.gg/grenade-predictor',
		es: 'https://scope.gg/grenade-predictor',
	},

	strategy: {
		ru: 'https://app.scope.gg/ru/strategy',
		en: 'https://app.scope.gg/strategy',
		pt: 'https://app.scope.gg/strategy',
		fr: 'https://app.scope.gg/strategy',
		es: 'https://app.scope.gg/strategy',
	},

	team: {
		ru: 'https://scope.gg/teammate-ru',
		en: 'https://scope.gg/teammate',
		pt: 'https://scope.gg/teammate',
		fr: 'https://scope.gg/teammate',
		es: 'https://scope.gg/teammate',
	},

	comparing: {
		ru: '/comparing-ru',
		en: '/comparing',
		pt: '/comparing',
		fr: '/comparing',
		es: '/comparing',
	},

	faceitStats: {
		ru: 'https://scope.gg/faceit-stats-ru',
		en: 'https://scope.gg/faceit-stats',
		pt: 'https://scope.gg/faceit-stats',
		fr: 'https://scope.gg/faceit-stats',
		es: 'https://scope.gg/faceit-stats',
	},
};
